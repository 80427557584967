import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';

import { useApi } from '@/hooks/useApi';
import { Activity } from '@/models/activity';

import { ConsentDocument, useGetPendingConsentDocuments } from './consents';

export const useIntentionStateForActivity = (
  activity: string | undefined,
  isCollectingIntentions: boolean,
): UseQueryResult<boolean> => {
  const api = useApi();
  return useQuery(
    ['pending-documents', activity],
    async () => {
      const response = await api.get(`/api/intentions/state/${activity}`);
      return !!response.data.collected;
    },
    {
      enabled: typeof activity === 'string' && isCollectingIntentions,
    },
  );
};

interface UseGetContentsResult {
  state: 'loading' | 'error' | 'success';
  pendingConsents: ConsentDocument[] | null;
  shouldCollectConsents: boolean;
  intentionState: boolean;
}

export const useGetConsentsDocuments = (activity?: Activity): UseGetContentsResult => {
  const pendingConsents = useGetPendingConsentDocuments(activity?.company?.id);
  const intentionState = useIntentionStateForActivity(activity?.slug, !!activity?.intentionsCollection);

  const state = useMemo(() => {
    if (pendingConsents.isLoading || intentionState.isLoading) {
      return 'loading';
    }

    if (pendingConsents.isError || intentionState.isError) {
      return 'error';
    }

    return 'success';
  }, [pendingConsents, intentionState]);

  return useMemo(() => {
    const shouldCollectConsents = !!activity?.intentionsCollection && !intentionState.data;

    return {
      state,
      pendingConsents: pendingConsents.data || null,
      intentionState: intentionState.data || false,
      shouldCollectConsents,
    };
  }, [activity, pendingConsents, intentionState]);
};
