import { FC } from 'react';

export const Document1Icon: FC = () => (
  <svg viewBox="0 0 144 114" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_ddd_8783_122540)">
      <rect x="16" y="7.99997" width="112" height="82" rx="8" fill="var(--surface-two)" />
      <rect x="26" y="20" width="20" height="20" rx="10" fill="#7067D2" />
      <rect x="57" y="26" width="46" height="7" rx="2" fill="#7067D2" />
      <rect x="26" y="49" width="86" height="3" rx="1" fill="#C1BFD7" />
      <rect x="26" y="56" width="86" height="3" rx="1" fill="#C1BFD7" />
      <rect x="26" y="63" width="86" height="3" rx="1" fill="#C1BFD7" />
      <rect x="26" y="70" width="86" height="3" rx="1" fill="#C1BFD7" />
      <rect x="26" y="77" width="86" height="3" rx="1" fill="#C1BFD7" />
    </g>
    <defs>
      <filter
        id="filter0_ddd_8783_122540"
        x="0"
        y="-3.05176e-05"
        width="144"
        height="114"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="0.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.160784 0 0 0 0 0.160784 0 0 0 0 0.2 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8783_122540" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.160784 0 0 0 0 0.160784 0 0 0 0 0.2 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_8783_122540" result="effect2_dropShadow_8783_122540" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="8" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.16 0 0 0 0 0.162353 0 0 0 0 0.2 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="effect2_dropShadow_8783_122540" result="effect3_dropShadow_8783_122540" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_8783_122540" result="shape" />
      </filter>
    </defs>
  </svg>
);
