import { FC } from 'react';

export const Document3Icon: FC = () => (
  <svg viewBox="0 0 144 114" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_ddd_8783_122580)">
      <rect x="16" y="7.99997" width="112" height="82" rx="8" fill="var(--surface-two)" />
      <rect x="58" y="24" width="57" height="3" rx="1.5" fill="#C1BFD7" />
      <rect x="58" y="40" width="57" height="3" rx="1.5" fill="#C1BFD7" />
      <rect x="58" y="56" width="57" height="3" rx="1.5" fill="#C1BFD7" />
      <rect x="58" y="72" width="57" height="3" rx="1.5" fill="#C1BFD7" />
      <path
        d="M33.3312 30V20.662H30.8952L27.3252 24.162L28.9212 25.828L30.5032 24.26V30H33.3312ZM38.4888 30.168C41.2748 30.168 42.6328 27.872 42.6328 25.338C42.6328 22.804 41.2748 20.522 38.4888 20.522C35.7028 20.522 34.3448 22.804 34.3448 25.338C34.3448 27.872 35.7028 30.168 38.4888 30.168ZM38.4888 27.676C37.5928 27.676 37.1868 26.92 37.1868 25.338C37.1868 23.756 37.5928 23.014 38.4888 23.014C39.3848 23.014 39.7908 23.756 39.7908 25.338C39.7908 26.92 39.3848 27.676 38.4888 27.676ZM47.2798 30.168C50.0658 30.168 51.4238 27.872 51.4238 25.338C51.4238 22.804 50.0658 20.522 47.2798 20.522C44.4938 20.522 43.1358 22.804 43.1358 25.338C43.1358 27.872 44.4938 30.168 47.2798 30.168ZM47.2798 27.676C46.3838 27.676 45.9778 26.92 45.9778 25.338C45.9778 23.756 46.3838 23.014 47.2798 23.014C48.1758 23.014 48.5818 23.756 48.5818 25.338C48.5818 26.92 48.1758 27.676 47.2798 27.676Z"
        fill="#C1BFD7"
      />
      <path
        d="M33.3312 46V36.662H30.8952L27.3252 40.162L28.9212 41.828L30.5032 40.26V46H33.3312ZM38.4888 46.168C41.2748 46.168 42.6328 43.872 42.6328 41.338C42.6328 38.804 41.2748 36.522 38.4888 36.522C35.7028 36.522 34.3448 38.804 34.3448 41.338C34.3448 43.872 35.7028 46.168 38.4888 46.168ZM38.4888 43.676C37.5928 43.676 37.1868 42.92 37.1868 41.338C37.1868 39.756 37.5928 39.014 38.4888 39.014C39.3848 39.014 39.7908 39.756 39.7908 41.338C39.7908 42.92 39.3848 43.676 38.4888 43.676ZM47.2798 46.168C50.0658 46.168 51.4238 43.872 51.4238 41.338C51.4238 38.804 50.0658 36.522 47.2798 36.522C44.4938 36.522 43.1358 38.804 43.1358 41.338C43.1358 43.872 44.4938 46.168 47.2798 46.168ZM47.2798 43.676C46.3838 43.676 45.9778 42.92 45.9778 41.338C45.9778 39.756 46.3838 39.014 47.2798 39.014C48.1758 39.014 48.5818 39.756 48.5818 41.338C48.5818 42.92 48.1758 43.676 47.2798 43.676Z"
        fill="#C1BFD7"
      />
      <path
        d="M33.848 62.154C36.746 62.154 38.748 60.446 38.748 57.324C38.748 54.594 37.362 52.508 34.296 52.508C32.084 52.508 30.418 53.838 30.418 55.868C30.418 57.856 32.042 58.962 33.722 58.962C34.576 58.962 35.416 58.626 35.892 58.01C35.892 58.024 35.892 58.038 35.892 58.052C35.892 58.962 35.038 59.662 33.848 59.662C33.162 59.662 32.658 59.536 32.126 59.158L30.936 61.342C31.678 61.846 32.784 62.154 33.848 62.154ZM34.59 56.568C34.03 56.568 33.288 56.358 33.288 55.756C33.288 55.364 33.764 55 34.464 55C35.458 55 35.85 55.56 35.906 56.19C35.528 56.484 35.066 56.568 34.59 56.568ZM43.704 62.168C45.916 62.168 47.582 60.838 47.582 58.808C47.582 56.82 45.958 55.714 44.278 55.714C43.424 55.714 42.584 56.05 42.108 56.666C42.108 56.652 42.108 56.652 42.108 56.638C42.108 55.504 43.228 55.014 44.152 55.014C44.838 55.014 45.342 55.14 45.874 55.518L47.064 53.334C46.322 52.83 45.216 52.522 44.152 52.522C41.254 52.522 39.252 54.23 39.252 57.352C39.252 60.082 40.638 62.168 43.704 62.168ZM43.522 59.676C42.542 59.676 42.15 59.116 42.094 58.486C42.472 58.192 42.934 58.108 43.41 58.108C43.97 58.108 44.712 58.318 44.712 58.92C44.712 59.312 44.236 59.676 43.522 59.676Z"
        fill="#C1BFD7"
      />
      <path
        d="M33.8275 78.154C36.7255 78.154 38.7275 76.446 38.7275 73.324C38.7275 70.594 37.3415 68.508 34.2755 68.508C32.0635 68.508 30.3975 69.838 30.3975 71.868C30.3975 73.856 32.0215 74.962 33.7015 74.962C34.5555 74.962 35.3955 74.626 35.8715 74.01C35.8715 74.024 35.8715 74.038 35.8715 74.052C35.8715 74.962 35.0175 75.662 33.8275 75.662C33.1415 75.662 32.6375 75.536 32.1055 75.158L30.9155 77.342C31.6575 77.846 32.7635 78.154 33.8275 78.154ZM34.5695 72.568C34.0095 72.568 33.2675 72.358 33.2675 71.756C33.2675 71.364 33.7435 71 34.4435 71C35.4375 71 35.8295 71.56 35.8855 72.19C35.5075 72.484 35.0455 72.568 34.5695 72.568ZM43.3755 78.168C46.1615 78.168 47.5195 75.872 47.5195 73.338C47.5195 70.804 46.1615 68.522 43.3755 68.522C40.5895 68.522 39.2315 70.804 39.2315 73.338C39.2315 75.872 40.5895 78.168 43.3755 78.168ZM43.3755 75.676C42.4795 75.676 42.0735 74.92 42.0735 73.338C42.0735 71.756 42.4795 71.014 43.3755 71.014C44.2715 71.014 44.6775 71.756 44.6775 73.338C44.6775 74.92 44.2715 75.676 43.3755 75.676Z"
        fill="#C1BFD7"
      />
      <path
        d="M97.9521 24.9592L108.858 29.2268C109.755 29.5778 109.686 30.8694 108.757 31.1228L104.644 32.2447C104.302 32.3379 104.035 32.6047 103.942 32.9463L102.82 37.0598C102.567 37.9891 101.275 38.058 100.924 37.1611L96.6565 26.2548C96.3387 25.4427 97.1401 24.6414 97.9521 24.9592Z"
        fill="#7067D2"
      />
    </g>
    <defs>
      <filter
        id="filter0_ddd_8783_122580"
        x="0"
        y="-3.05176e-05"
        width="144"
        height="114"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="0.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.160784 0 0 0 0 0.160784 0 0 0 0 0.2 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8783_122580" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.160784 0 0 0 0 0.160784 0 0 0 0 0.2 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_8783_122580" result="effect2_dropShadow_8783_122580" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="8" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.16 0 0 0 0 0.162353 0 0 0 0 0.2 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="effect2_dropShadow_8783_122580" result="effect3_dropShadow_8783_122580" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_8783_122580" result="shape" />
      </filter>
    </defs>
  </svg>
);
