import { useTranslations } from 'next-intl';
import { FC, useMemo } from 'react';
import ContentLoader from 'react-content-loader';

import { useGetMyPublicResume } from '@/features/resume-builder';
import { UserStepType } from '@/features/user-steps/types';
import { UserStep } from '@/features/user-steps/user-step';
import { Document1Icon } from '@/shared/icons/Document1Icon';
import { Document2Icon } from '@/shared/icons/Document2Icon';
import { Document3Icon } from '@/shared/icons/Document3Icon';

import styles from './UserSteps.module.css';

export const UserStepsSkeleton: FC = () => (
  <ContentLoader
    width="100%"
    height="100%"
    viewBox="0 0 550 270"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="mt-10"
    backgroundColor="var(--surface-muted)"
    foregroundColor="var(--surface-default)"
  >
    <rect width="172.857" height="270" rx="8" fill="#D9D9D9" />
    <rect x="377.143" width="172.857" height="270" rx="8" fill="#D9D9D9" />
    <rect x="188.571" width="172.857" height="270" rx="8" fill="#D9D9D9" />
  </ContentLoader>
);

export const UserSteps: FC = () => {
  const t = useTranslations();
  const resume = useGetMyPublicResume();
  const steps: UserStepType[] = useMemo(() => {
    const hasCompletedResume = resume.data?.state === 'completed';
    const hasTest = resume.data?.test_results?.length;
    return [
      {
        step_order: 1,
        title: t('pages.start_point_page.create_cv'),
        description: t('pages.start_point_page.create_cv_description'),
        icon: <Document1Icon />,
        state: hasCompletedResume ? 'passed' : 'active',
        button_text: t('pages.start_point_page.create_cv'),
        href: '/resume',
      },
      {
        step_order: 2,
        title: t('pages.start_point_page.power_up_cv'),
        description: t('pages.start_point_page.power_up_cv_description'),
        icon: <Document2Icon />,
        // eslint-disable-next-line no-nested-ternary
        state: !hasCompletedResume ? 'next' : !hasTest ? 'active' : 'passed',
        button_text: t('pages.start_point_page.take_test'),
        href: '/certificates',
      },
      {
        step_order: 3,
        title: t('pages.start_point_page.choose_job'),
        description: t('pages.start_point_page.choose_job_description'),
        icon: <Document3Icon />,
        state: hasCompletedResume && hasTest ? 'active' : 'next',
        button_text: t('pages.start_point_page.view_jobs'),
        href: '/jobs',
      },
    ];
  }, [resume.data]);

  return (
    <>
      {resume.isLoading ? (
        <UserStepsSkeleton />
      ) : (
        <div className="relative flex flex-col mt-6 mx-auto w-full max-w-[288px] gap-4 md:max-w-none md:gap-6 md:flex-row md:grid grid-cols-3 grid-rows-[max-content]">
          <div className="absolute left-4 h-full w-[1px] md:left-0 md:w-full md:h-[1px] md:top-4 bg-transparent">
            <div className={styles.gradient} />
          </div>

          {steps.map((step) => (
            <UserStep userStep={step} key={step.step_order} />
          ))}
        </div>
      )}
    </>
  );
};
