import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { authModel, authModuleSelectors } from '@/entities/authorization';
import { useGetConsentsDocuments } from '@/entities/consents/model';
import { getUnfinishedStepsInfo } from '@/features/auth-flow/model/steps';
import { AuthFlowStepKey } from '@/features/auth-flow/model/types';
import { useActivity } from '@/queries/auth/useActivity';
import { useCurrentUser } from '@/queries/auth/useCurrentUser';
import { selectProgrammingLanguageForActivity } from '@/store';
import { ENV_SERVER_URL } from '@/utils/constants';

const PATHNAME_FOR_STEP: Record<AuthFlowStepKey, string> = {
  data_confirm: '/authorize/confirm',
  phone_confirm: '/authorize/phone-confirmation',
  consents: '/authorize/privacy-confirm',
};

interface UseAuthFlowStepsResult {
  state: 'loading' | 'error' | 'success';
  steps?: AuthFlowStepKey[];
  redirectUrl?: string | null;
  nextUrl?: string | null;
}

/**
 * Hook that returns array of current steps for auth flow with activity context
 * In case of error (there are no activity context or pathname is invalid) returns null
 */
export const useAuthFlowSteps = (): UseAuthFlowStepsResult => {
  const authStatus = authModel.useIsAuthorized();
  const user = useCurrentUser();
  const activity = useActivity();

  const { pathname } = useRouter();
  const signupFinishedAt = useSelector(authModuleSelectors.signupFinishedAt);
  const { state, shouldCollectConsents } = useGetConsentsDocuments(activity.data);
  const programmingLanguageForActivity = useSelector(selectProgrammingLanguageForActivity);

  const redirectContinueUrl = useMemo(
    () =>
      `${ENV_SERVER_URL}/redirect/continue${
        programmingLanguageForActivity ? `?programming_language=${programmingLanguageForActivity}` : ''
      }`,
    [programmingLanguageForActivity],
  );

  return useMemo(() => {
    if (state === 'loading' || authStatus === 'loading' || activity.isLoading || user.isLoading) {
      return { state: 'loading' };
    }

    if (state === 'error' || authStatus === 'error' || activity.isError || user.isError) {
      return { state: 'error' };
    }

    const steps = getUnfinishedStepsInfo({
      pathname,
      signupFinishedAt: signupFinishedAt || null,
      requirePhone: !!activity.data?.requirePhone,
      usedPhoneVerified: !!user.data?.phoneVerifiedAt,
      phoneVerificationIsSkippable: !!activity.data?.phoneVerificationIsSkippable,
      skippedPhoneVerification: !!user.data?.skippedPhoneVerification,
      shouldCollectConsents,
    });

    if (!steps) {
      return { state: 'error' };
    }

    let redirectUrl: string | null = null;
    if (steps.length > 0) {
      const firstStep = steps[0];

      if (pathname !== PATHNAME_FOR_STEP[firstStep]) {
        redirectUrl = PATHNAME_FOR_STEP[firstStep];
      }
    } else {
      redirectUrl = redirectContinueUrl;
    }

    let nextUrl: string | null = null;
    if (steps.length <= 1) {
      nextUrl = redirectContinueUrl;
    } else if (steps.length > 1) {
      nextUrl = PATHNAME_FOR_STEP[steps[1]];
    }

    return { state: 'success', steps, redirectUrl, nextUrl };
  }, [
    state,
    activity.data,
    authStatus,
    pathname,
    user.data,
    activity.isLoading,
    user.isLoading,
    activity.isError,
    user.isError,
    signupFinishedAt,
    shouldCollectConsents,
    redirectContinueUrl,
  ]);
};
