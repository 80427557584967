import { AnchorButton, Button } from '@geeckocom/core-ui';
import clsx from 'clsx';
import Link, { LinkProps } from 'next/link';
import { useTranslations } from 'next-intl';
import { FC, HTMLProps } from 'react';

import { UserStateEnum, UserStepType } from '@/features/user-steps/types';
import { CheckIcon } from '@/shared/icons/CheckIcon';

interface UserStepTagProps extends Omit<LinkProps & HTMLProps<HTMLDivElement>, 'href'> {
  state: UserStateEnum;
  href: string;
}

export const UserStepTag: FC<UserStepTagProps> = (props) => {
  const { href, children, state } = props;
  if (state === 'active' || state === 'next') {
    return (
      <Link href={href} className="cursor-pointer" passHref>
        <AnchorButton variant={state === 'active' ? 'primary' : 'outlined'}>{children}</AnchorButton>
      </Link>
    );
  }
  return (
    <div className="opacity-20">
      <Button>{children}</Button>
    </div>
  );
};

interface Props {
  userStep: UserStepType;
}

export const UserStep: FC<Props> = ({ userStep }) => {
  const t = useTranslations();
  return (
    <div className="flex w-full gap-2 md:gap-4 md:flex-col md:items-center">
      <div
        className={clsx(
          userStep.state === 'active' && 'border-accent-one-bright-different',
          userStep.state !== 'active' && 'border-surface-default  bg-surface-default',
          'relative z-1 flex items-center justify-center w-8 h-8 bg-surface-default rounded-lg border text-accent-one-bright-different font-semibold flex-shrink-0',
        )}
      >
        <span className={clsx(userStep.state !== 'active' && 'text-text-tertiary')}>
          {userStep.state === 'passed' ? <CheckIcon /> : userStep.step_order}
        </span>
      </div>

      <div
        className={clsx(
          userStep.state === 'active' && '!border-accent-one-bright-different',
          userStep.state === 'passed' && '!border-surface-muted bg-surface-muted',
          'flex flex-col items-center p-6 pb-8 bg-surface-default border-surface-default rounded-lg border md:min-h-[326px] md:h-full',
        )}
      >
        <div className="w-[144px] h-[114px]">{userStep.icon}</div>
        <h2 className="font-gotham font-bold text-text-primary text-lg leading-6">{userStep.title}</h2>
        <p className="mt-2 text-sm leading-[19px] text-text-secondary">{userStep.description}</p>
        <div className="mt-4 md:mt-auto md:pt-4">
          <UserStepTag state={userStep.state} href={userStep.href}>
            {userStep.state !== 'passed' ? userStep.button_text : t('pages.start_point_page.done')}
          </UserStepTag>
        </div>
      </div>
    </div>
  );
};
