import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import { FC, useEffect } from 'react';

import { authModel, Authorized } from '@/entities/authorization';
import { AuthFlowGuard } from '@/features/auth-flow';
import { UserSteps } from '@/features/user-steps';
import { useCurrentUser } from '@/queries/auth/useCurrentUser';
import { Registered } from '@/shared/components/registered-layout';
import { PageTitle } from '@/shared/page-title';

const Home: FC = () => {
  const t = useTranslations();
  const router = useRouter();
  const user = useCurrentUser();
  const authorizedStatus = authModel.useIsAuthorized();

  useEffect(() => {
    if (router.query.redirect === '/resume') {
      router.replace('/resume');
    } else if (router.isReady && authorizedStatus !== 'loading') {
      if (authorizedStatus === 'unauthorized') {
        router.replace('/signup');
      } else {
        // router.replace('/profile');
      }
    }
  }, [authorizedStatus, router.query]);

  return (
    <Authorized>
      <AuthFlowGuard>
        <Registered>
          <PageTitle />
          <div className="flex flex-col items-center mx-auto w-full text-center md:max-w-[798px]">
            {user.data?.firstName && user.data.lastName ? (
              <p className="text-sm text-[#4D4E57] mt-6">
                {t('pages.start_point_page.welcome_text', { name: `${user.data.firstName} ${user.data.lastName}` })}
              </p>
            ) : null}
            <h1 className="title !mt-2 max-w-[260px] md:max-w-none">{t('pages.start_point_page.title')}</h1>
            <UserSteps />
          </div>
        </Registered>
      </AuthFlowGuard>
    </Authorized>
  );
};

export default Home;
