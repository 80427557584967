import { getUnixTimestamp } from '@/shared/lib/get-unix-timestamp';

import { AuthFlowStepKey } from './types';

interface GetStepsInfoData {
  pathname: string;
  signupFinishedAt: number | null;
  usedPhoneVerified: boolean;
  requirePhone: boolean;
  phoneVerificationIsSkippable: boolean;
  skippedPhoneVerification: boolean;
  shouldCollectConsents: boolean;
}

export const getUnfinishedStepsInfo = (data: GetStepsInfoData): AuthFlowStepKey[] | null => {
  const result: AuthFlowStepKey[] = [];

  if (
    data.pathname === '/authorize/confirm' &&
    (data.signupFinishedAt === null || getUnixTimestamp() - data.signupFinishedAt > 60 * 60)
  ) {
    result.push('data_confirm');
  }

  if (data.requirePhone) {
    const phonePassed = data.usedPhoneVerified || (data.skippedPhoneVerification && data.phoneVerificationIsSkippable);

    if (!phonePassed) {
      result.push('phone_confirm');
    }
  }

  if (data.shouldCollectConsents) {
    result.push('consents');
  }

  return result;
};
