import { Button } from '@geeckocom/core-ui';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import { useDispatch, useSelector } from 'react-redux';

import { authModule, authModuleSelectors } from '@/entities/authorization';
import { useCurrentUser } from '@/queries/auth/useCurrentUser';
import { Footer } from '@/shared/footer';
import { FCWithChildren } from '@/shared/types';
import { Header } from '@/widgets/header';

import { Container } from '../container';
import styles from './Registered.module.css';

export const Registered: FCWithChildren = ({ children }) => {
  const t = useTranslations();
  const router = useRouter();
  const dispatch = useDispatch();
  const userData = useCurrentUser();

  const impersonationToken = useSelector(authModuleSelectors.impersonationToken);
  const handleLogoutFromImpersonation = async () => {
    dispatch(authModule.actions.setIsTransition(true));
    dispatch(authModule.actions.setImpersonationToken(undefined));
    await router.push({ query: {} });
  };

  return (
    <div className={styles.ProfileLayout}>
      <Header />
      {userData.data && impersonationToken ? (
        <Container>
          <div className="m-4 bg-white rounded-[16px] p-4 flex items-center flex-wrap sm:flex-nowrap">
            <div>{t('widgets.registered_layout.warning_impersonation')}</div>
            <div className="mt-4 sm:mt-0 sm:ml-auto">
              <Button size="sm" variant="outlined" onClick={handleLogoutFromImpersonation}>
                {t('widgets.registered_layout.logout_button')}
              </Button>
            </div>
          </div>
        </Container>
      ) : null}
      {children}
      <Footer />
    </div>
  );
};
