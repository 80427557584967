import { useQuery } from 'react-query';
import { UseQueryResult } from 'react-query/types/react/types';

import { useApi } from '@/hooks/useApi';

export interface ConsentDocument {
  id: number;
  type: 'privacy_policy' | 'terms_of_service' | 'custom';
  link: string | null;
  required: boolean;
  acceptable: boolean;
  title: string | null;
  code: string;
  description: string | null;
}

interface ConsentDocumentApiResponse {
  id: number;
  type: 'privacy_policy' | 'terms_of_service' | 'custom';
  link: string | null;
  required: boolean;
  acceptable: boolean;
  title: string | null;
  code: string;
  description: string | null;
}

const decodeConsentDocument = (json: ConsentDocumentApiResponse): ConsentDocument => json;

export const useGetPendingConsentDocuments = (companyId: number | undefined): UseQueryResult<ConsentDocument[]> => {
  const api = useApi();
  return useQuery(
    ['pending-documents', companyId],
    async () => {
      const response = await api.get<{ data: ConsentDocumentApiResponse[] }>(`/api/consents?company_id=${companyId}`);
      return response.data.data.map(decodeConsentDocument);
    },
    {
      enabled: typeof companyId === 'number' && companyId > 0,
    },
  );
};
