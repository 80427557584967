import { FC } from 'react';

interface Props {
  size?: number;
}

export const CheckIcon: FC<Props> = ({ size = 16 }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.8">
      <path
        d="M1.66663 8.71791L5.82048 12.8718L14.3333 3.1282"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
