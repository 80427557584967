import { FC } from 'react';

export const Document2Icon: FC = () => (
  <svg viewBox="0 0 144 114" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_ddd_8783_122563)">
      <rect x="16" y="8" width="112" height="82" rx="8" fill="var(--surface-two)" />
      <rect x="68" y="49" width="44" height="3" rx="1" fill="#EAE9F5" />
      <rect x="26" y="49" width="80" height="3" rx="1" fill="#7067D2" />
      <rect x="26" y="56" width="86" height="3" rx="1" fill="#EAE9F5" />
      <rect x="26" y="63" width="86" height="3" rx="1" fill="#EAE9F5" />
      <rect x="26" y="70" width="86" height="3" rx="1" fill="#EAE9F5" />
      <rect x="26" y="56" width="77" height="3" rx="1" fill="#7067D2" />
      <rect x="26" y="63" width="59" height="3" rx="1" fill="#7067D2" />
      <path
        d="M71.4308 21.3941L48.6321 37.1778C47.826 37.7358 48.2209 39 49.2013 39H94.7987C95.7791 39 96.174 37.7358 95.3679 37.1778L72.5692 21.3941C72.2268 21.157 71.7732 21.157 71.4308 21.3941Z"
        fill="#7067D2"
      />
    </g>
    <defs>
      <filter
        id="filter0_ddd_8783_122563"
        x="0"
        y="0"
        width="144"
        height="114"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="0.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.160784 0 0 0 0 0.160784 0 0 0 0 0.2 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_8783_122563" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="4" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.160784 0 0 0 0 0.160784 0 0 0 0 0.2 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="effect1_dropShadow_8783_122563" result="effect2_dropShadow_8783_122563" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="8" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.16 0 0 0 0 0.162353 0 0 0 0 0.2 0 0 0 0.04 0" />
        <feBlend mode="normal" in2="effect2_dropShadow_8783_122563" result="effect3_dropShadow_8783_122563" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_8783_122563" result="shape" />
      </filter>
    </defs>
  </svg>
);
