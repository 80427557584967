import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { PageError } from '@/components/PageError';
import { useAuthFlowSteps } from '@/features/auth-flow';
import { Loading } from '@/layouts/Loading';
import { useActivity } from '@/queries/auth/useActivity';
import { FCWithChildren } from '@/shared/types';

export const AuthFlowGuard: FCWithChildren = ({ children }) => {
  const { replace } = useRouter();
  const result = useAuthFlowSteps();
  const activity = useActivity();
  useEffect(() => {
    if (result.state !== 'error' && result.state && result.redirectUrl) {
      // Remove activity context before redirecting to activity
      if (activity.data) {
        try {
          sessionStorage.removeItem('activity_context');
        } catch {
          //
        }
        replace(result.redirectUrl);
      }
    }
  }, [result]);

  if (result.state === 'error') {
    // TODO: Error texts
    return <PageError />;
  }

  if (activity.data && (result.state === 'loading' || result.redirectUrl)) {
    // TODO: AuthLayoutSkeleton
    return <Loading />;
  }

  return <>{children}</>;
};
